import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'

interface ItemProps {
  title?: string
}

export interface Props {
  description?: string
  items?: ItemProps[]
  title?: string
}

export const Details = memo(function Details({
  description,
  items,
  title,
}: Props) {
  if (!title && !description && !items) {
    return null
  }

  return (
    <Container>
      <Wrapper>
        <Inner>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {items ? (
            <FadeInUp>
              <Items>
                {items.map((item, index) => (
                  <Item key={index}>{item.title}</Item>
                ))}
              </Items>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
        </Inner>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 11.125vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.5625rem 0;
  }
`

const Wrapper = styled.div`
  max-width: 77.75rem;
  margin: 0 auto;
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  text-align: center;

  @media (max-width: 1199px) {
    padding: 3.5rem 1.5625rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.8125rem;
  letter-spacing: 0.02em;
  line-height: 3.4375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const Items = styled.ul`
  margin: 2.2rem 0 -1rem;
`

const Item = styled.li`
  position: relative;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 1.1875rem;
  letter-spacing: 0.02em;
  line-height: 1.6875rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }
`

const Description = styled.div`
  margin-top: 2.2rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.375rem;
`
